import $ from 'jquery';
import ifdo from '../legacy/utils/ifdo.js';

const jquery = $.noConflict();

jquery.fn.ifdo = ifdo;

jquery.fn.buttonDisable = (disable) => {
  if (disable == null)
    disable = true;

  const $el = $(this);

  $el.button({
    loadingText: $el.is('input') ? $el.val() : $el.html()
  });

  if (disable)
    $el.button('loading');
  else
    $el.button('reset');
};

export default jquery;
